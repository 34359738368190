<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h3>{{ Object.entries(this.data).length === 0 ? "Add New" : "Edit" }} Learning Centre</h3>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">

        <div class="vx-col w-full mt-5">
          <label class="block mb-2">Director Name</label>
          <v-select :options="directorOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="directorFilter" class="mb-4 md:mb-0" />
        </div>

        <vs-input
          label="Company Name"
          v-model="companyName"
          class="w-full mt-5"
          name="companyName"
          v-validate="'required|alpha_spaces'"
          data-vv-as="Company Name"/>
        <span class="text-danger text-sm" v-show="errors.has('companyName')">{{ errors.first('companyName') }}</span>

        <vs-input
          label="Contact Number"
          v-model="contactNumber"
          class="mt-5 w-full"
          name="contactNumber"
          v-validate="'required|numeric'"
          data-vv-as="Contact Number"/>
        <span class="text-danger text-sm" v-show="errors.has('contactNumber')">{{ errors.first('contactNumber') }}</span>

        <vs-input
          class="w-full mt-5"
          name="email"
          v-validate="'required|email'"
          data-vv-as="Email"
          label="Email"
          v-model="email"/>
        <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first('email') }}</span>

        <div class="vx-col w-full mt-5">
          <label class="block mb-2">Country</label>
          <v-select :options="countryOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="countryFilter" class="mb-4 md:mb-0" />
        </div>
        <div class="vx-col w-full mt-5">
          <label class="block mb-2">State</label>
          <v-select :options="stateOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="stateFilter" class="mb-4 md:mb-0" />
        </div>

        <vs-input
          label="Street Address"
          v-model="displayAddress"
          class="mt-5 w-full"
          name="displayAddress"
          v-validate="'required'"
          data-vv-as="Street Address"/>
        <span class="text-danger text-sm" v-show="errors.has('displayAddress')">{{ errors.first('displayAddress') }}</span>

        <vs-input
          label="Town/City"
          v-model="suburb"
          class="mt-5 w-full"
          name="suburb"
          v-validate="'required|alpha_spaces'"
          data-vv-as="Town/City"/>
        <span class="text-danger text-sm" v-show="errors.has('suburb')">{{ errors.first('suburb') }}</span>

        <vs-input
          label="Postcode"
          v-model="postCode"
          class="mt-5 w-full"
          name="postCode"
          v-validate="'required|numeric'"
          data-vv-as="Postcode"/>
        <span class="text-danger text-sm" v-show="errors.has('postCode')">{{ errors.first('postCode') }}</span>

        <div class="vx-col w-full mt-5 vs-con-loading__container">
          <template v-if="logo">
            <!-- Image Container -->
            <div class="img-container w-64 mx-auto flex items-center justify-center">
              <img :src="logo" alt="img" class="responsive">
            </div>

            <!-- Image upload Buttons -->
            <div class="modify-img flex justify-between mt-5">
              <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*">
              <vs-button class="mr-4"  href="#" @click="$refs.updateImgInput.click()">Update Logo</vs-button>
              <vs-button  class="vs-button-secondary" @click="logo = null">Remove Logo</vs-button>
            </div>
          </template>

          <div class="upload-img mt-5" v-if="!logo">
            <input type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/*">
            <vs-button @click="$refs.uploadImgInput.click()">Upload Logo</vs-button>
          </div>
        </div>
      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center justify-between p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Submit</vs-button>
      <vs-button class="vs-button-secondary" @click="isSidebarActiveLocal = false">Cancel</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import vSelect from 'vue-select'
  import {mapActions} from "vuex";
  import _ from 'lodash';

  export default {
    data() {
      return {
        dataId: null,
        directorId: "",
        companyName: "",
        contactNumber: "",
        fullName: "",
        email: "",
        logo:null,
        image: null,
        settings: { // perfect scrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60,
        },
        countryOptions: [],
        stateOptions: [],
        countryFilter: {label: 'All', value: 'all'},
        stateFilter: {label: 'All', value: 'all'},
        suburb:"",
        postCode:"",
        displayAddress:"",
        customError: "",
        directorOptions: [],
        directorFilter: {label: 'All', value: 'all'},
      }
    },
    props: {
      isSidebarActive: {
        type: Boolean,
        required: true
      },
      data: {
        type: Object,
        default: () => {
        },
      },
    },
    watch: {
      isSidebarActive(val) {
        if (!val) return
        if (Object.entries(this.data).length === 0) {
          this.initValues()
          this.$validator.reset()
        } else {
          this.dataId = this.data._id
          this.companyName = this.data.name
          this.contactNumber = this.data.phoneNumber
          this.fullName = this.data.directorDetails[0].fullName
          this.directorId = this.data.directorDetails[0]._id
          this.email = this.data.email
          this.logo = this.data.logo
          this.image = this.data.logo
          let filter = {label: this.data.country.name, value: this.data.country.name};
          let selectedCountry = _.find(this.countryOptions, filter);
          this.countryFilter = selectedCountry
          this.stateOptions = this.countryFilter.states;
          this.stateFilter = {label: this.data.address.state, value: this.data.address.state};
          this.suburb =this.data.address.suburb;
          this.postCode = this.data.address.postCode;
          this.displayAddress =  this.data.address.displayAddress;
          this.directorFilter = {label: this.data.directorDetails[0].fullName, value: this.data.directorDetails[0]._id};
          this.initValues()
        }
        // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
      },
      countryFilter(obj) {
        //this.stateFilter = { label: 'All', value: 'all' };
        this.stateOptions = obj.states;
      },
    },
    computed: {
      isSidebarActiveLocal: {
        get() {
          return this.isSidebarActive
        },
        set(val) {
          if (!val) {
            this.$emit('closeSidebar')
          }
        }
      },
      isFormValid() {
        return !this.errors.any()
          && this.companyName
          && this.contactNumber
          && this.directorFilter.value != "all"
          && this.email
          && this.logo
          && this.countryFilter.value != "all"
          && this.stateFilter.value != "all"
          && this.suburb
          && this.postCode
          && this.displayAddress
      }
    },
    methods: {
      ...mapActions("center", [
        "updateCenter",
        "addCenter",
        "imageUpload",
        "getAllCountries",
        "getDirectorList"
      ]),
      showMessage(title, message, color) {
        this.$vs.notify({
          title: title,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: color
        })
      },
      initValues() {
        if (this.data._id) return
        this.dataId = null
        this.contactNumber = ""
        this.companyName = ""
        this.fullName = ""
        this.email = ""
        this.logo = null
        this.image = null
        this.countryFilter = {label: 'All', value: 'all'};
        this.stateFilter = {label: 'All', value: 'all'};
        this.directorFilter = {label: 'All', value: 'all'};
        this.postCode = ""
        this.suburb = ""
        this.displayAddress = ""
      },
      fetchDirectorList() {
        this.getDirectorList()
          .then((res) => {
            let data = res.data.data;
            this.directorOptions = data.map((item) => {
              return {
                label: item.fullName,
                value:item._id
              }
            })
          })

      },
      updateCurrImg(input) {
        if (input.target.files && input.target.files[0]) {
          this.image = input.target.files[0];
          var reader = new FileReader()
          reader.onload = e => {
            this.logo = e.target.result
          }
          reader.readAsDataURL(input.target.files[0])
        }
      },
      async submitData() {
        await this.$validator.validateAll().then(result => {
          if (result) {
            let data = new FormData();
            data.append('id', this.dataId);
            data.append('name', this.companyName);
            data.append('phoneNumber', this.contactNumber);
            data.append('directorId', this.directorFilter.value);
            data.append('fullName', this.directorFilter.label);
            data.append( 'email', this.email);
            data.append('logo', this.image);
            data.append('state', this.stateFilter.value);
            data.append('suburb', this.suburb);
            data.append('postCode', this.postCode);
            data.append('displayAddress', this.displayAddress);
            this.customError = "";
            if (this.dataId !== null) {
              this.$vs.loading()
              this.updateCenter(data)
                .then((res) => {
                  if (res.status === 200) {
                    this.showMessage("Success", "Centre Updated successfully.", "success");
                    data.country = this.countryFilter.value;
                    this.$emit('refreshData', true);
                  }
                  this.$vs.loading.close()
                })
                .catch(err => {
                  if (err.response.status === 403) {
                    this.showMessage("Failed", err.response.data.message, "danger");
                  }
                  this.$vs.loading.close()
                  //console.error(err)
                })
            } else {
              if(this.logo == null){
                this.customError = "Image field is required.";
                return ;
              }
              this.$vs.loading()
              this.addCenter(data)
                .then((res) => {
                  if (res.status === 200) {
                    this.$vs.loading.close()
                    this.$emit('refreshData', true)
                    this.showMessage("Success", "Centre added successfully.", "success");
                  }
                })
                .catch(err => {
                  this.$vs.loading.close()
                  if(err.response.status === '422') {
                    this.showMessage("Failed", err.response.data.message, "danger");
                  }
                  if (err.response.status === 403) {
                    this.showMessage("Failed", err.response.data.message, "danger");
                  }
                  this.showMessage("Failed","Failed to add centre", "danger");
                  // console.error(err)
                })
            }
            this.$emit('closeSidebar')
          }
        })
        // this.initValues()
      },
    },
    created(){
      this.getAllCountries()
        .then((res) => {
          this.countryOptions = this.$store.state.center.countryOptions;
        })
        .catch(err => {
          //console.error(err)
        })
      this.fetchDirectorList();
    },
    components: {
      vSelect,
      VuePerfectScrollbar,
    }
  }
</script>

<style lang="scss" scoped>
  .add-new-data-sidebar {
    ::v-deep .vs-sidebar--background {
      z-index: 52010;
    }

    ::v-deep .vs-sidebar {
      z-index: 52010;
      width: 400px;
      max-width: 90vw;

      .img-upload {
        margin-top: 2rem;

        .con-img-upload {
          padding: 0;
        }

        .con-input-upload {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  .scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
  }
</style>
