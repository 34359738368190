<template>
  <div>
    <LearningCentreSideBar
      :isSidebarActive="addNewDataSidebar"
      @closeSidebar="toggleDataSidebar"
      @refreshData="refreshData"
      :data="sidebarData"
    />

    <vx-card>
      <div class="flex justify-between align-items-center mb-8">
        <h2 class="mb-0">Learning Centres</h2>
      </div>
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :max-items="dataTableParams.limit"
        :data="learningCentreData"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        @selected="handleSelected"
      >
        <div slot="header" class="w-full mb-8">
          <div class="vx-row mb-5">
            <div class="vx-col md:w-1/2 w-full">
              <vs-input
                class="w-full search-input admin-search-placholder"
                v-model="searchQuery"
                placeholder="Search..."
              />
            </div>
            <div class="vx-col md:w-1/2 w-full flex justify-end">
              <vs-button
                color="primary"
                type="filled"
                @click="addNewLearningCentre"
                >Add Centre</vs-button
              >
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
              <label class="block mb-2">Country</label>
              <v-select
                :options="countryOptions"
                :clearable="false"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="countryFilter"
                class="mb-4 md:mb-0"
              />
            </div>
            <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
              <label class="block mb-2">Region</label>
              <v-select
                :options="stateOptions"
                :clearable="false"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="stateFilter"
                class="mb-4 md:mb-0"
              />
            </div>
            <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
              <label class="block mb-2">View By</label>
              <v-select
                :options="viewOptions"
                :clearable="false"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="viewByFilter"
                class="mb-4 md:mb-0"
              />
            </div>
            <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
              <label class="block mb-2">Sort By</label>
              <v-select
                :options="sortOptions"
                :clearable="false"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="sortByFilter"
                class="sort-dropdown"
              />
            </div>
          </div>
        </div>

        <template slot="thead">
          <vs-th>Name</vs-th>
          <vs-th>Group</vs-th>
          <vs-th>Country</vs-th>
          <vs-th>Region</vs-th>
          <vs-th>No. Admin Team</vs-th>
          <vs-th>No. Teachers</vs-th>
          <vs-th>No. Children</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.name">
              {{ tr.name | capitalize }}
            </vs-td>
            <vs-td :data="tr.companyName">
              {{
                tr.companyName.length > 0 ? tr.companyName[0] : "" | capitalize
              }}
            </vs-td>
            <vs-td :data="tr.country">
              {{ tr.country | capitalize }}
            </vs-td>
            <vs-td :data="tr.address.state">
              {{ tr.address.state | capitalize }}
            </vs-td>
            <vs-td :data="tr.noAdmins">
              {{ tr.noAdmins }}
            </vs-td>
            <vs-td :data="tr.noTeachers">
              {{ tr.noTeachers }}
            </vs-td>
            <vs-td :data="tr.noChildren">
              {{ tr.noChildren }}
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <a
                href="#"
                class="nav-link d-flex align-items-center active"
                @click.stop="viewHandler(tr._id)"
              >
                <feather-icon
                  icon="EyeIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current icon-eye"
                />
              </a>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
    <scroll-loader
      :loader-method="getLearningCenterDataPreset"
      :loader-enable="loadMore"
    >
      <div></div>
    </scroll-loader>
  </div>
</template>

<script>
import Vue from "vue";
import ScrollLoader from "vue-scroll-loader";
import { mapActions } from "vuex";
import vSelect from "vue-select";
import LearningCentreSideBar from "./learningCentreSidebar";

Vue.use(ScrollLoader);

export default {
  components: {
    "v-select": vSelect,
    LearningCentreSideBar
  },
  data() {
    return {
      loadMore: true,
      isMounted: false,
      totalDocs: 0,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        limit: 20,
        page: 0,
        country: "all",
        state: "all"
      },
      learningCentreData: [],
      viewByFilter: { label: "Centres", value: "center" },
      viewOptions: [
        { label: "Centres", value: "center" },
        { label: "Group", value: "group" }
      ],
      sortByFilter: { label: "Name", value: "name" },
      sortOptions: [
        { label: "Name", value: "name" },
        { label: "No. of Teachers", value: "noTeachers" },
        { label: "No. of Children", value: "noChildren" }
      ],
      countryOptions: [],
      countryFilter: { label: "All", value: "all" },
      stateOptions: [],
      stateFilter: { label: "All", value: "all" },
      serverResponded: false,
      searchQuery: "",
      awaitingSearch: false,
      addNewDataSidebar: false,
      sidebarData: {}
    };
  },
  watch: {
    countryFilter(obj) {
      this.stateOptions = obj.states;
      this.stateFilter = { label: "All", value: "all" };
      this.dataTableParams.page = 1;
      this.getLearningCenterDataPreset("sort");
    },
    stateFilter(obj) {
      this.dataTableParams.page = 1;
      this.getLearningCenterDataPreset("sort");
    },
    searchQuery: function(val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.dataTableParams.page = 1;
          this.getLearningCenterDataPreset("sort");
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
    sortByFilter(obj) {
      this.dataTableParams.page = 1;
      this.dataTableParams.sort = obj.value;
      this.getLearningCentreData("sort");
    },
    viewByFilter(obj) {
      if (this.viewByFilter.value === "group") {
        this.$router.push({ name: "group-management" });
      }
      if (this.viewByFilter.value === "center") {
        this.$router.push({ name: "learning-centers" });
      }
    }
  },

  methods: {
    ...mapActions("center", ["getLearningCentreList", "getAllCountries"]),
    getLearningCenterDataPreset(from = null) {
      this.dataTableParams.country =
        this.countryFilter.value == "all" ? "" : this.countryFilter.value;
      this.dataTableParams.state =
        this.stateFilter.value == "all" ? "" : this.stateFilter.value;
      this.dataTableParams.search = this.searchQuery;
      if (!from) {
        this.dataTableParams.page++;
      }
      this.getLearningCentreData(from);
    },
    getLearningCentreData(from = null) {
      this.$vs.loading();
      this.getLearningCentreList(this.dataTableParams)
        .then(async res => {
          this.$vs.loading.close();
          let centers = this.$store.state.center.centers;
          const a = await res.data.data.docs;
          if (from) {
            this.learningCentreData = a;
          } else {
            if (a.length) {
              this.totalDocs = centers.pagination.total;
              this.page = centers.pagination.page;
              a.forEach(item => {
                this.learningCentreData.push(item);
              });
            }
          }

          a.length < this.pageSize && (this.loadMore = false);

          this.serverResponded = true;
        })
        .catch(err => {
          this.$vs.loading.close();
          console.error(err);
        });
    },
    handleSearch(searching) {
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getLearningCentreData();
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getLearningCentreData();
    },
    handleSort(key, active) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getLearningCentreData();
    },
    viewHandler(id) {
      this.$router.push({ name: "learning-centre-view", params: { id: id } });
    },
    editDetailHandler(data, id) {
      this.sidebarData = data;
      this.dataId = id;
      this.toggleDataSidebar(true);
    },
    addNewLearningCentre() {
      this.$router.push({ name: "learning-centre-add" });
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
    },
    refreshData() {
      this.getAllCountries()
        .then(res => {
          this.countryOptions = this.$store.state.group.countryOptions;
          this.getLearningCentreData();
        })
        .catch(err => {
          console.error(err);
        });
    },
    handleSelected(tr) {
      let id = tr._id;
      this.$router.push({ name: "learning-centre-view", params: { id: id } });
    }
  },
  computed: {
    totalPage: function() {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    }
  },
  created() {
    this.getAllCountries()
      .then(res => {
        this.countryOptions = this.$store.state.center.countryOptions;
      })
      .catch(err => {
        console.error(err);
      });
  }
};
</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}

.vs-input--placeholder.normal {
  padding: 0 10px !important;
  top: 10px !important;
}
</style>
